<template>
  <component :is="as" class="sharedTitle" :class="classes" :style="styles">
    <span v-if="withSidelines" class="sharedTitle__line"></span>
    <NuxtLinkLocale v-if="props.to" :to="props.to">
      <div class="sharedTitle__inner" :style="innerStyles">
        <slot name="prepend">
          <component :is="props.prependIcon" v-if="props.prependIcon" :style="props.prependedIconStyles" />
        </slot>
        <SharedText
          as="span"
          font-case="upper"
          :color="Colors.NEUTRAL.WHITE"
          :size="size"
          :weight="FontWeights.BOLD"
          v-bind="props.textProps"
        >
          <slot></slot>
        </SharedText>
        <slot name="append">
          <component :is="props.appendIcon" v-if="props.appendIcon" :style="props.appendedIconStyles" />
        </slot>
      </div>
    </NuxtLinkLocale>
    <div v-else class="sharedTitle__inner" :style="innerStyles">
      <slot name="prepend">
        <component :is="props.prependIcon" v-if="props.prependIcon" :style="props.prependedIconStyles" />
      </slot>
      <SharedText
        as="span"
        font-case="upper"
        :color="Colors.NEUTRAL.WHITE"
        :size="size"
        :weight="FontWeights.BOLD"
        v-bind="props.textProps"
      >
        <slot></slot>
      </SharedText>
      <slot name="append">
        <component :is="props.appendIcon" v-if="props.appendIcon" :style="props.appendedIconStyles" />
      </slot>
    </div>
    <span v-if="withSidelines" class="sharedTitle__line"></span>
  </component>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import type { ISharedTitleProps } from './SharedTitle.types';
import { FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';

const COMPONENT_NAME = 'sharedTitle';

const LINE_COLOR_VAR = '--line-color';
const LINE_WIDTH_VAR = '--line-width';
const LINE_HEIGHT_VAR = '--line-height';
const TITLE_OUTER_GAP = '--outer-gap';
const TITLE_INNER_GAP = '--inner-gap';
const BAND_BG_COLOR_VAR = '--band-background-color';
const BAND_BG_DIRECTION_VAR = '--band-gradient-direction';
const BAND_PADDING_VERTICAL_VAR = '--band-vertical-padding';

const { proceedCssValue } = GlobalUtils.CSS;

const props = withDefaults(defineProps<ISharedTitleProps>(), {
  appendedIconStyles: () => ({}),
  as: 'h1',
  disableLines: false,
  fullLinesWidth: false,
  hideSideLines: false,
  prependedIconStyles: () => ({}),
  size: 'default',
  textProps: () => ({}),
  toDirection: -90,
  variant: 'default',
});

const {
  hideSideLines,
  sideLineColor,
  sideLineWidth,
  sideLineHeight,
  fullLinesWidth,
  variant,
  bandColor,
  bandPadding,
  outerGap,
  innerGap,
  innerPadding,
  innerMargin,
  toDirection,
} = toRefs(props);

const isBand = computed(() => variant.value === 'band');

const withSidelines = computed(() => {
  if (isBand.value) return false;
  return !hideSideLines.value;
});

const classes = computed(() => ({
  [`${COMPONENT_NAME}--banded`]: isBand.value,
  [`${COMPONENT_NAME}--full-lines-width`]: fullLinesWidth.value,
  [`${COMPONENT_NAME}--no-sides`]: !withSidelines.value,
  [`${COMPONENT_NAME}--link`]: !!props.to,
}));

const styles = computed(() => {
  const properties: CSSProperties = {};

  if (sideLineColor?.value) {
    if (typeof sideLineColor.value === 'string' && !sideLineColor.value.includes(',')) {
      properties[LINE_COLOR_VAR] = sideLineColor.value;
    } else if (Array.isArray(sideLineColor.value) || typeof sideLineColor.value === 'string') {
      properties[LINE_COLOR_VAR] = GlobalUtils.CSS.fillGradient(
        sideLineColor.value,
        GlobalUtils.Converting.toDegs(toDirection.value),
      );
    } else {
      properties[LINE_COLOR_VAR] = GlobalUtils.CSS.createLinearGradient(sideLineColor.value).backgroundImage;
    }
  }

  if (sideLineWidth?.value) properties[LINE_WIDTH_VAR] = proceedCssValue(sideLineWidth.value);
  if (sideLineHeight?.value) properties[LINE_HEIGHT_VAR] = proceedCssValue(sideLineHeight.value);
  if (outerGap?.value) properties[TITLE_OUTER_GAP] = proceedCssValue(outerGap.value);
  if (innerGap?.value) properties[TITLE_INNER_GAP] = proceedCssValue(innerGap.value);

  if (isBand.value && bandColor?.value) {
    if (Array.isArray(bandColor.value)) properties[BAND_BG_COLOR_VAR] = bandColor.value.join(',');
    else if (typeof bandColor.value === 'string') properties[BAND_BG_COLOR_VAR] = bandColor.value;
    else {
      bandColor.value.fallback && (properties[BAND_BG_COLOR_VAR] = bandColor.value.fallback);
      bandColor.value.colorStops &&
        (properties[BAND_BG_COLOR_VAR] = Array.isArray(bandColor.value.colorStops)
          ? bandColor.value.colorStops.join(',')
          : bandColor.value.colorStops);
      bandColor.value.toDirection && (properties[BAND_BG_DIRECTION_VAR] = bandColor.value.toDirection);
    }

    if (bandPadding?.value) properties[BAND_PADDING_VERTICAL_VAR] = proceedCssValue(bandPadding.value);
  }

  return properties;
});

const innerStyles = computed(() => {
  const properties: CSSProperties = {};

  if (innerPadding?.value) Object.assign(properties, GlobalUtils.CSS.handleBlockSpacing('padding', innerPadding.value));
  if (innerMargin?.value) Object.assign(properties, GlobalUtils.CSS.handleBlockSpacing('margin', innerMargin.value));

  return properties;
});
</script>

<style src="./SharedTitle.scss" scoped lang="scss"></style>
